// 
// not yet implemented, this will be a dashboard where all the graphs can be seen
//



import React, { Component } from 'react';

class Overview extends Component {

	render() {

		return (
			<div className="container-fluid">
				<p>overview</p>
			</div>
		);
	}

}

export default Overview;