/*

	stateMachine
	============

	1: startup screen "Lädt..."
	2: login wall
	0: regular interface


	tabIDs
	======
	-1: add sensor
	-2: overview (not yet implemented, but reserved)
	-3: settings
	0+: sensorIDs

*/

// -------------
// -- IMPORTS --
// -------------

import React, { Component } from 'react';

import $ from 'jquery';
//import Chart from "react-apexcharts";

import Tabs from './tabs';
import SingleMeasurement from './singleMeasurement';
import Overview from './overview';
import Settings from './settings';
import NewMeasurement from './newMeasurement';
import Warnings from './warnings';
import PasswordWall from './passwordWall';

const crypto = require('crypto');
//const { promisify } = require('util');

//const { } = require('mathjs');

//import Button from 'react-bootstrap/Button';



// ------------------------------
// -- FIND URL FOR DATA SERVER --
// ------------------------------
var urlDev = "";
var localPort = 8899;
var localID   = "111";
if(window.location.href.search("localhost")!==-1) {
	urlDev = "http://localhost:" + localPort + "/" + localID + "/";
	//var urlDev = "http://172.20.10.8:8899/bma/";
	//var urlDev = "";
	//var urlDev = "https://plugnlog.ch/111/";
} else if(/(http:\/\/[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+:).*/.exec(window.location.href)) {  // regular expression search
	var localURL = /(http:\/\/[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+:).*/.exec(window.location.href)[1];
	urlDev       = localURL + localPort + "/" + localID + "/";
}


//add leading zeroes. source: https://gist.github.com/endel/321925f6cafa25bbfbde
Number.prototype.pad = function(size) {
	var s = String(this);
	while (s.length < (size || 2)) {
		s = "0" + s;
	}
	return s;
}


// -----------------------------
// -- PASSWORD AUTHENTICATION --
// -----------------------------

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function hashPassword(password, salt = 'e435bf533270407a4b454&-.da7fb@92647a15afc6a1c71ab8892ecf8634b4a9', iterations = 2048, length = 32, algo = 'sha512') {
	return crypto.pbkdf2Sync(password, salt, iterations, length, algo).toString('hex');
}

var hashedPassword = getCookie("hashedPassword");




// ----------------------------
// -- PREPARE INITIALISATION --
// ----------------------------

var session_id;

const newFormTemplate = {
	sensorname: {
		raw: "",
		format: /^.+$/,
		ok: false
	},
	email: {
		raw: "",
		format: /^.*$/,
		ok: true
	},
	sensorID: {
		raw: "",
		format: /^(\d+)\.\d+$/,
		message: "Bitte geben Sie die Sensor-ID ein.",
		processed: {
			raw:    "",
			id:     0
		},
		ok: false
	},
	messrate: {
		raw: "1",
		format: /^\d+$/,
		min: 1,
		max: 86400,
		message: "Bitte geben Sie die Messrate als Ganzzahl ein.",
		processed: 1,
		ok: true
	},
	channels: {
		ids: [],
		type: []
	},
	validation: false,
	jumpers: [],
	jumperModes: [],
	specific: {}
};

const passwordSettingsTemplate = {
	showModal: false,
	type: "",
	validation: false,
	currentPassword: {
		value: "",
		isValid: true,
		feedback: ""
	},
	newPassword1: {
		value: "",
		isValid: true,
		feedback: ""
	},
	newPassword2: {
		value: "",
		isValid: true,
		feedback: ""
	}
}

class Site extends Component {

	//state = initState();
	state = {
		currentTabID: 0,
		config: {},
		catalog: {},
		data: {},
		session_id: "",
		status: [],
		stateMachine: 1,
		loadingText: "Lädt...",
		login: {
			passwordAlert: "",
			enteredPassword: "",
			formEnabled: true,
			rememberLogin: false	
		},
		settings: {
			passwordSettings: $.extend(true, {}, passwordSettingsTemplate)
		}
	};

	//gather data
	//...

	// --------------------
	// -- INITIALISATION --
	// --------------------

	initState = (callback) => { // (callback is a local variable)


		let stateLocal = {
			newForm: $.extend(true, {}, newFormTemplate),
			changeForm: $.extend(true, {}, newFormTemplate),
			lastRefresh: Date.now(),
			showModal: false,
			chart: {
				options: {
					noData: {
						text: "(noch keine Daten vorhanden)",
						style: {
							fontSize: "16px"
						}
					},
					chart: {
						toolbar: { //doesn't work.....
							autoselected: "zoom"
						},
						id: "basic-bar",
						animations: {
							enabled: false,
							easing: "easeinout",
							speed: 300,
							dynamicAnimation: {
								enabled: true,
								easing: "easeinout",
								speed: 300
							}
						}
					},
					xaxis: {
						type: "numeric",
						labels: {
							formatter: function (val) {
								var stamp = new Date(val);
								return stamp.toLocaleTimeString();
							},
						},
						title: {
							text: 'Zeit'
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								//let exponent = Math.round(Math.log(value)/Math.log(10));
								//return(Math.round(value * (10**(exponent + 3))) / (10**(exponent + 3)));
								return(value);
							}
						}
					}
				}
			}
		};
		let stateServer = {};
		let url = urlDev + "app/init/"
		$.ajax({
			'async': true,
			'global': false,
			'url': url,
			'dataType': "json",
			'timeout': 60000,
			'type': "POST",
			'data': {
				hashedPassword: hashedPassword
			},
			'success': function (data) {
				stateServer = data;
			},
			'error': function () {
				stateServer = {
					config: {
						sensors: []
					},
					catalog: {
						sensors: []
					},
					currentTabID: -3,
					status: [{type: "danger", text: "Verbindung zu Server fehlgeschlagen."}]
				};
			}
		}).done(() => {

			// check login
			if (this.checkLogin(stateServer)) {

				//concatenate local and online data
				let state = Object.assign({}, stateLocal, stateServer);

				//set tab to tab in URL anchor
				if(window.location.href.split("#")[1] === "add-sensor")
					state.currentTabID = -1;
				if(window.location.href.split("#")[1] === "settings")
					state.currentTabID = -3;
				if(/sensor-([0-9]+).*/.exec(window.location.href.split("#")[1])) {
					var foundSensorID = Number(/sensor-([0-9]+).*/.exec(window.location.href.split("#")[1])[1]);
					if(state.config.sensors.indexOf(foundSensorID) >= 0) {
						state.currentTabID = foundSensorID;
					}
				}

				//rewrite data
				state.rawData = Object.assign({}, state.data);
				for (var key in state.data) {
					state.data[key] = this.prepareData(state.data[key], state.config[key], state.catalog);
				}
				//console.log(state);
				session_id = stateServer.session_id;
				this.setState(state, () => {
					callback();
					setTimeout(this.refresh, 1000, true);
				});
			}
		}).fail(() => {

			this.setState({loadingText: "Verbindung zum Server fehlgeschlagen."});
		});
	};

	// -------------
	// -- REFRESH --
	// -------------			

	refresh = (firstTime) => {

		if(Date.now() - this.state.lastRefresh < 290000) {
			//console.log(this.state.data);
			var url = urlDev + "app/sync/"
			var json = null;
			$.ajax({
				'async': true,
				'global': false,
				'url': url,
				'dataType': "json",
				'type': "POST",
				'timeout': 2000,
				'data': {
					session_id: session_id,
					hashedPassword: hashedPassword
				},
				'success': function (data) {
					json = data;
				},
				'error': function(data) {
					json = {
						data: {},
						status: [{type: "danger", text: "Verbindung zu Server fehlgeschlagen."}]
					};
				}
			}).done(() => {

				if(this.checkLogin(json)) {

					let currentTabID = this.state.currentTabID;
					let config  = this.state.config;
					let newData = this.state.data;
					let newRawData = this.state.rawData;

					//update config
					if(JSON.stringify(json.config) !== "{}") {
						config = Object.assign({}, config, json.config);
					}

					//add new sensors
					if(JSON.stringify(json.newSensors) !== "[]") {
						config.sensors = config.sensors.concat(json.newSensors);
						json.newSensors.forEach((newSensor) => {
							newData[newSensor]    = [];
							newRawData[newSensor] = [];
						});
					}

					//remove deleted sensors
					if (JSON.stringify(json.deletedSensors) !== "[]") {
						json.deletedSensors.forEach((deletedSensor) => {
							config.sensors.splice(config.sensors.indexOf(deletedSensor),1);
							delete config[deletedSensor];
							delete newData[deletedSensor];
							delete newRawData[deletedSensor]
							if(this.state.currentTabID === deletedSensor) {
								currentTabID = -1;
								window.location.hash = "add-sensor";
							}
						});
					}

					//update changed sensors
					if(JSON.stringify(json.changedSensors)) {
						json.changedSensors.forEach((changedSensor) => {
							config.sensors[config.sensors.indexOf(changedSensor[0])] = changedSensor[1];
							var tempData = newData[changedSensor[0]];
							delete newData[changedSensor[0]];
							newData[changedSensor[1]] = tempData;
							tempData = newRawData[changedSensor[0]];
							delete newRawData[changedSensor[0]];
							newRawData[changedSensor[1]] = tempData;
							if(this.state.currentTabID === changedSensor[0]) {
								currentTabID = changedSensor[1];
							}
						});
					}

					//prepare data
					config.sensors.forEach((sensor) => {
						if(typeof json.data[sensor] !== 'undefined') {
							if(typeof newData[sensor] !== 'undefined' && newData[sensor].length > 0) {
								//newData[sensor] = newData[sensor].concat(json.data[sensor]);
								let newestData = JSON.parse(JSON.stringify(newData[sensor]));

								// .  
								// .  
		   						// .
								// .
								// . GEBASTELT!!!
								// .
								// .
								// .
								// .
								json.data[sensor].forEach((subData) => {
									newRawData[sensor].push(subData);
									config[sensor].active.forEach((channel, key) => {
										newestData[key].data.push([
											subData[0], 
											subData[1][key] * config[sensor].conversion[key][0] + config[sensor].conversion[key][1]
										]);
									});
								});
								newData[sensor] = newestData;
							} else {
								//newData[sensor] = json.data[sensor];
								newData[sensor] = this.prepareData(json.data[sensor], config[sensor], this.state.catalog);

							}
						}
					});

					let status = json.status;

					this.setState({config: config, data: newData, rawData: newRawData, status: status, lastRefresh: Date.now(), currentTabID: currentTabID}, () => {
						setTimeout(this.refresh, 1000, true);		
					});
				}
			}).fail(() => {
				//console.log("was here...");
				if(firstTime) {
					this.refresh(false);
				} else {
					this.setState({status: json.status});
					setTimeout(this.refresh, 1000, true);		
				}
			});

		} else {
			//window.location.reload();
			let currentTabID = this.state.currentTabID;
			this.initState(() => {
				this.setState({currentTabID: currentTabID});
			});
			// !!!!!!!!!
		}
	};

	// ------------------
	// -- PREPARE DATA --
	// ------------------

	prepareData = (data, config, catalog) => {

		let series   = [];
		let channels = [];

		config.active.forEach((channel, key) => {
			series.push({
				data: [],
				name: config.names[key] + " [" + config.einheiten[key] + "]"
			});
			channels.push(channels.length);
		});
		data.forEach((subData) => {
			config.active.forEach((channel, key) => {
				series[key].data.push([
					subData[0], 
					subData[1][key] * config.conversion[key][0] + config.conversion[key][1]
				]);
			});
		});
		return(series);
	};

	// -------------------
	// -- TRIGGER START --
	// -------------------

	componentDidMount() {
		this.initState(() => {});
	}

	// -----------------------
	// -- HANDLER FUNCTIONS --
	// -----------------------

	//Change Tab
	handleChangeTab = (newTabID) => {
		this.setState({currentTabID: newTabID});
	};

	//Changes in newForm
	handleNewFormChange = (field, value, callback = () => {}) => {

		//init state vars
		let newForm = {};
		let isNewSensor = !this.state.showModal;
		if(isNewSensor) {
			newForm = this.state.newForm;
		} else {
			newForm = this.state.changeForm;
		}
		let sensor  = newForm.sensorID.processed.raw;
		let id      = newForm.sensorID.processed.id;
		let matchJ  = field.match(/^jumper(\d+)$/);
		let matchE  = field.match(/^einheit(\d+)$/);
		let matchT  = field.match(/^toggle(\d+)$/);
		let matchMi = field.match(/^min(\d+)$/);
		let matchMa = field.match(/^max(\d+)$/);

		//Jumper settings
		if(matchJ) {

			//save jumper setting
			newForm.jumpers[matchJ[1]] = Number(value);

			//calculate ID
			let dive = this.state.catalog[sensor].address;
			let i = 0;
			while(i<newForm.jumpers.length) {
				dive = dive[newForm.jumpers[i]];
				i += 1;
			}
			id = dive;
			newForm.sensorID.processed.id = id;

			//if sensor with same ID exists
			if((this.state.config.sensors.indexOf(id)<0 && newForm.sensorID.message.match(/^Sensor/)) || (!isNewSensor && id===this.state.currentTabID)) {
				newForm.sensorID.ok = true;
				newForm.sensorID.message = "";
			}

		//Einheit settings
		} else if(matchE) {
			newForm.channels.unit[matchE[1]] = value;

		//channelActive settings
		} else if(matchT) {
			newForm.channels.active[matchT[1]] = !newForm.channels.active[matchT[1]];

		//min settings
		} else if(matchMi) {
			let channel = matchMi[1];
			newForm.channels.minRaw[channel] = value;

			//if not defined
			if(value==="") {
				newForm.channels.min[channel] = -1;
				newForm.channels.minOk[channel] = true;

				//check max
				if(newForm.channels.maxMessage[channel] === "Der Wert ist tiefer als der untere Schwellwert." && newForm.channels.maxOk[channel]===false) {
					newForm.channels.maxOk[channel] = true;
				}

			//if defined
			} else {
				let conversion = this.state.catalog[sensor].conversion[newForm.channels.type[channel]][newForm.channels.unit[channel]];
				let rawValue = Math.round((Number(value) - conversion[1]) / conversion[0]);
				newForm.channels.min[channel] = rawValue;
				//console.log(rawValue);

				//check conditions
				if(rawValue < 0) {
					newForm.channels.minOk[channel] = false;
					newForm.channels.minMessage[channel] = "Der Wert ist zu tief für den Messbereich des Sensors.";
				
				} else if(rawValue > newForm.channels.maxResult[channel]) {
					newForm.channels.minOk[channel] = false;
					newForm.channels.minMessage[channel] = "Der Wert ist zu hoch für den Messbereich des Sensors.";
				
				} else if(rawValue >= newForm.channels.max[channel] && newForm.channels.max[channel] !== -1) {
					newForm.channels.minOk[channel] = false;
					newForm.channels.minMessage[channel] = "Der Wert ist höher als der obere Schwellwert.";
					if(newForm.channels.maxOk[channel]===true) {
						newForm.channels.maxOk[channel] = false;
						newForm.channels.maxMessage[channel] = "Der Wert ist tiefer als der untere Schwellwert.";
					}

				} else {
					newForm.channels.minOk[channel] = true;

					//check max
					if(newForm.channels.maxMessage[channel] === "Der Wert ist tiefer als der untere Schwellwert." && newForm.channels.maxOk[channel]===false) {
						newForm.channels.maxOk[channel] = true;
					}
				}
			}

		//max settings
		} else if(matchMa) {
			let channel = matchMa[1];
			newForm.channels.maxRaw[channel] = value;

			//if not defined
			if(value==="") {
				newForm.channels.max[channel] = -1;
				newForm.channels.maxOk[channel] = true;

				//check min
				if(newForm.channels.minMessage[channel] === "Der Wert ist höher als der obere Schwellwert." && newForm.channels.minOk[channel]===false) {
					newForm.channels.minOk[channel] = true;
				}

			//if defined
			} else {
				let conversion = this.state.catalog[sensor].conversion[newForm.channels.type[channel]][newForm.channels.unit[channel]];
				let rawValue = Math.round((Number(value) - conversion[1]) / conversion[0]);
				newForm.channels.max[channel] = rawValue;
				//console.log(rawValue);

				//check conditions
				if(rawValue < 0) {
					newForm.channels.maxOk[channel] = false;
					newForm.channels.maxMessage[channel] = "Der Wert ist zu tief für den Messbereich des Sensors.";
				
				} else if(rawValue > newForm.channels.maxResult[channel]) {
					newForm.channels.maxOk[channel] = false;
					newForm.channels.maxMessage[channel] = "Der Wert ist zu hoch für den Messbereich des Sensors.";
				
				} else if(rawValue <= newForm.channels.min[channel] && newForm.channels.min[channel] !== -1) {
					newForm.channels.maxOk[channel] = false;
					newForm.channels.maxMessage[channel] = "Der Wert ist tiefer als der untere Schwellwert.";
					if(newForm.channels.minOk[channel]===true) {
						newForm.channels.minOk[channel] = false;
						newForm.channels.minMessage[channel] = "Der Wert ist höher als der obere Schwellwert.";
					}

				} else {
					newForm.channels.maxOk[channel] = true;

					//check min
					if(newForm.channels.minMessage[channel] === "Der Wert ist höher als der obere Schwellwert." && newForm.channels.minOk[channel]===false) {
						newForm.channels.minOk[channel] = true;
					}
				}
			}

		} else {
			newForm[field].raw = value;
			newForm[field].ok = Boolean(value.match(newForm[field].format));
		}

		//field specific actions
		switch(field) {
			case "sensorID":

				//if ok
				if(newForm.sensorID.ok) {

					//if sensor driver found
					if(this.state.catalog[value]) {

						//only update if sensor has changed from previously
						if(sensor!==value) {

							//process sensorID
							let match2 = value.match(newForm[field].format);
							sensor = value;
							id = Number(match2[1]);
							newForm.sensorID.processed.raw = sensor;
							newForm.sensorID.processed.id = id;
							
							//process channels
							newForm.channels = this.state.catalog[sensor].channels;
							newForm.channels.unit   = [];
							newForm.channels.min    = [];
							newForm.channels.max    = [];
							newForm.channels.minRaw = [];
							newForm.channels.maxRaw = [];
							newForm.channels.minOk  = [];
							newForm.channels.maxOk  = [];
							newForm.channels.minMessage = [];
							newForm.channels.maxMessage = [];
							newForm.channels.type.forEach((type, key) => {
								newForm.channels.unit.push(this.state.catalog.units[type][0]);
								newForm.channels.min.push(-1);
								newForm.channels.max.push(-1);
								newForm.channels.minRaw.push("");
								newForm.channels.maxRaw.push("");
								newForm.channels.minOk.push(true);
								newForm.channels.maxOk.push(true);
								newForm.channels.minMessage.push("");
								newForm.channels.maxMessage.push("");
							});

							//process Jumpers
							if(isNewSensor) {
								newForm.jumpers = this.state.catalog[sensor].jumpers.slice();
							} else {
								let dive = this.state.catalog[sensor].address;
								let i = 0;
								while(i<newForm.jumpers.length) {
									dive = dive[newForm.jumpers[i]];
									i += 1;
								}
								id = dive;
								newForm.sensorID.processed.id = id;
							}
							newForm.jumperModes = this.state.catalog[sensor].jumperModes;

							//if sensor with same ID exists
							if((this.state.config.sensors.indexOf(id)>=0) && (isNewSensor || id!==this.state.currentTabID)) {
								newForm.sensorID.message = "Sensor nicht kompatibel mit Sensor \"" + this.state.config[id].name + "\", beide besitzen dieselbe Adresse. Bitte tauschen Sie einen davon aus oder ändern Sie (falls möglich) die Jumper-Konfiguration.";
								newForm.sensorID.ok = false;
							}
						}
					} else {
						newForm.sensorID.message = "Sensor-Treiber nicht gefunden.";
						newForm[field].ok = false;
					}
				} else {				
					newForm.sensorID.message = "Eingegebene ID entspricht nicht dem Format. Bsp: 123.1.";
				}
				break;

			case "messrate":
				if(newForm.messrate.ok) {
					newForm.messrate.processed = Number(newForm.messrate.raw);
					if(newForm.messrate.processed < newForm.messrate.min) {
						newForm.messrate.message = "Messrate zu klein. Der Wert darf minimal "+newForm.messrate.min+" sein.";
						newForm.messrate.ok = false;
					}
					if(newForm.messrate.processed > newForm.messrate.max) {
						newForm.messrate.message = "Messrate zu gross. Der Wert darf maximal "+newForm.messrate.max+" sein.";
						newForm.messrate.ok = false;
					}
				}
				break;
			case "einheit":
				break;
			default:
				break;
		}
		//console.log(newForm);
		if(!this.state.showModal) {
			this.setState({newForm: newForm}, () => {
				//console.log(this.state);
				callback();
			});
		} else {
			this.setState({changeForm: newForm}, () => {
				//console.log(this.state);
				callback();
			});
		}
	};

	//Submit newForm
	handleSubmit = () => {

		//set validation state to true
		let newForm = {};
		let isNewSensor = this.state.showModal;
		if(!isNewSensor) {
			newForm = this.state.newForm;
		} else {
			newForm = this.state.changeForm;
		}
		newForm.validation = true;

		//iterate through channels
		let channelsOk = true;
		newForm.channels.type.forEach((type, key) => {
			if(!newForm.channels.minOk[key] && newForm.channels.active[key])
				channelsOk = false;
			if(!newForm.channels.maxOk[key] && newForm.channels.active[key])
				channelsOk = false;
		});

		//if everything is ok
		if(newForm.sensorname.ok && newForm.email.ok && newForm.sensorID.ok && newForm.messrate.ok && channelsOk) {

			//prepare state
			let id      = newForm.sensorID.processed.id;
			let oldID   = this.state.currentTabID;
			let sensor  = newForm.sensorID.processed.raw;
			let config  = this.state.config;
			let data    = this.state.data;
			let rawData = this.state.rawData;
			if(!isNewSensor) {
				config.sensors.push(id);
			} else if(id!==oldID) {
				config.sensors[config.sensors.indexOf(oldID)] = id;
			}
			let einheiten = [];
			let conversion = [];
			let active = [];
			let min = [];
			let max = [];
			let names = [];
			newForm.channels.type.forEach((type, key) => {
				if(newForm.channels.active[key]) {
					active.push(key);
					einheiten.push(newForm.channels.unit[key]);
					min.push(newForm.channels.min[key]);
					max.push(newForm.channels.max[key]);
					conversion.push(this.state.catalog[sensor].conversion[newForm.channels.type[key]][newForm.channels.unit[key]]);
					names.push(newForm.channels.names[key]);
				}
			});
			let sensorConfig = {
				name:       newForm.sensorname.raw,
				type:       sensor,
				conversion: conversion,
				messrate:   newForm.messrate.processed * 1000,
				jumpers:    newForm.jumpers,
				active:     active,
				einheiten:  einheiten,
				min:        min,
				max:        max,
				names:      names,
				email:      newForm.email.raw
			};
			config[id] = sensorConfig;
			if(isNewSensor) {
				data[id]    = data[oldID];
				rawData[id] = rawData[oldID];
				if(oldID!==id) {
					delete data[oldID];
					delete rawData[oldID];
				}
			} else {
				data[id]    = [];
				rawData[id] = [];
			}

			//truncate newForm
			newForm = $.extend(true, {}, newFormTemplate);

			//prepare data for server
			let serverData;
			if(!isNewSensor) {

				serverData = {
					hashedPassword: hashedPassword,
					session_id: session_id,
					newSensor: id,
					config: JSON.stringify(sensorConfig)
				};
			} else {

				serverData = {
					hashedPassword: hashedPassword,
					session_id: session_id,
					changedSensor: id,
					oldSensorID: oldID,
					config: JSON.stringify(sensorConfig)
				};
			}

			//send data to server
			var json = null;
			$.ajax({
				'async': false,
				'global': false,
				'url': urlDev + "app/settings/",
				'dataType': "json",
				'type': "POST",
				'timeout': 2000,
				'data': serverData,
				'success': function (data) {
					json = data;
				},
				'error': function () {
					window.alert("Synchronisation fehlgeschlagen (fehlerhafte Antwort vom Server)");
				}
			}).done(() => {
				//success
				if(json.succeeded) {
					//write new State
					this.setState({config: config, newForm: newForm, data: data, rawData: rawData, currentTabID: id, showModal: false});
					//console.log(config);					

				//failed
				} else {
					window.alert("Synchronisation fehlgeschlagen");
				}				
			});

		} else {	
			if(!isNewSensor) {
				this.setState({newForm: newForm});
			} else {
				this.setState({changeForm: newForm});
			}
		}
	};

	//check Login response from server
	checkLogin = (serverResponse) => {
		var login = this.state.login;
		if (serverResponse.stateMachine === 2) {
			login.formEnabled = true;
			login.loginActivated = serverResponse.loginActivated;
			login.passwordAlert = serverResponse.passwordAlert;
			this.setState({stateMachine: 2, login: login}, () => {
				$("#password").focus();
			});
			return false;
		} else {
			login.enteredPassword = "";
			login.loginActivated = serverResponse.loginActivated;
			login.passwordAlert = serverResponse.passwordAlert;
			this.setState({login: login});
			return true;
		}
	};

	//Change Password field
	handleChangePassword = (field, value) => {
		var login = this.state.login;
		if(field === "rememberLogin")
			login.rememberLogin = Boolean(value);
		else
			login.enteredPassword = value;
		this.setState({login: login});
	};

	//Submit password
	handleSubmitPassword = () => {
		var login = this.state.login;
		login.formEnabled = false;
		login.passwordAlert = "Lädt...";
		this.setState({login: login}, () => {

			hashedPassword = hashPassword(this.state.login.enteredPassword);

			//console.log("hashedPassword", hashedPassword);

			if(login.rememberLogin)
				setCookie("hashedPassword", hashedPassword, 7);
			else
				setCookie("hashedPassword", "", 0);

			this.initState(() => {});
		});
	};

	handleOpenPasswordModal = (type) => {
		var settings = this.state.settings;
		settings.passwordSettings = $.extend(true, {}, passwordSettingsTemplate);
		settings.passwordSettings.showModal = true;
		settings.passwordSettings.type = type;
		this.setState({settings: settings}, () => {
			if(settings.passwordSettings.type === "activatePassword")
				$("#newPassword1").focus();
			else
				$("#currentPassword").focus();
		});
	};

	handleEscapePasswordSettings = () => {
		var settings = this.state.settings;
		settings.passwordSettings = $.extend(true, {}, passwordSettingsTemplate);
		this.setState({settings: settings});
	};

	handleChangePasswordSettings = (field, value) => {
		var settings = this.state.settings;
		settings.passwordSettings[field].value = value;
		this.setState({settings: settings});
	};

	handleSubmitPasswordSettings = (field, value) => {
		var settings = this.state.settings;

		//activatePassword
		if(settings.passwordSettings.type === "activatePassword") {

			//new passwords don't match
			if(settings.passwordSettings.newPassword1.value !== settings.passwordSettings.newPassword2.value) {
				//console.log("new passwords don't match");
				this.passwordSettingsFeedback("", "Die Passwörter stimmen nicht überein.", "Die Passwörter stimmen nicht überein.");

			//new passwords are empty
			} else if(settings.passwordSettings.newPassword1.value === "") {
				//console.log("empty");
				this.passwordSettingsFeedback("", "Bitte geben Sie ein neues Passwort ein.", "Bitte geben Sie ein neues Passwort ein.");

			//control succeeded
			} else {
				const data = {
					type: "activatePassword",
					newPassword: hashPassword(settings.passwordSettings.newPassword1.value)
				};
				this.submitPasswordSettings(data);				
			}
		}

		//changePassword
		if(settings.passwordSettings.type === "changePassword") {

			//new passwords don't match
			if(settings.passwordSettings.newPassword1.value !== settings.passwordSettings.newPassword2.value) {
				//console.log("new passwords don't match");
				this.passwordSettingsFeedback("", "Die Passwörter stimmen nicht überein.", "Die Passwörter stimmen nicht überein.");

			//new passwords are empty
			} else if(settings.passwordSettings.newPassword1.value === "") {
				//console.log("empty");
				this.passwordSettingsFeedback("", "Bitte geben Sie ein neues Passwort ein.", "Bitte geben Sie ein neues Passwort ein.");

			//current password doesn't match
			} else {
				const currentPasswordHash = hashPassword(settings.passwordSettings.currentPassword.value);				
				if(currentPasswordHash !== hashedPassword) {
					//console.log("current password doesn't match");
					this.passwordSettingsFeedback("Falsches Passwort.", "", "");

				//control succeeded
				} else {
					const data = {
						type: "changePassword",
						newPassword: hashPassword(settings.passwordSettings.newPassword1.value)
					};
					this.submitPasswordSettings(data);				
				}
			}
		}

		//deactivatePassword
		if(settings.passwordSettings.type === "deactivatePassword") {

			//current password doesn't match
			const currentPasswordHash = hashPassword(settings.passwordSettings.currentPassword.value);				
			if(currentPasswordHash !== hashedPassword) {
				//console.log("current password doesn't match");
				this.passwordSettingsFeedback("Falsches Passwort.", "", "");

			//control succeeded
			} else {
				const data = {
					type: "deactivatePassword",
					newPassword: ""
				};
				if(window.confirm("Wollen Sie den Passwortschutz wirklich deaktivieren?"))
					this.submitPasswordSettings(data);
				else
					this.handleEscapePasswordSettings();			
			}
		}
	};

	passwordSettingsFeedback = (feedbackCurrentPassword, feedbackNewPassword1, feedbackNewPassword2) => {
		var settings = this.state.settings;
		settings.passwordSettings.validation = true;
		settings.passwordSettings.currentPassword.feedback = feedbackCurrentPassword;
		settings.passwordSettings.newPassword1.feedback    = feedbackNewPassword1;
		settings.passwordSettings.newPassword2.feedback    = feedbackNewPassword2;
		settings.passwordSettings.currentPassword.isValid  = feedbackCurrentPassword === "";
		settings.passwordSettings.newPassword1.isValid     = feedbackNewPassword1    === "";
		settings.passwordSettings.newPassword2.isValid     = feedbackNewPassword2    === "";
		this.setState({settings: settings});
	};

	submitPasswordSettings = (passwordSettings, callback = () => {}) => {

		//console.log("submitted password settings. data:");
		//console.log(passwordSettings);

		var url = urlDev + "app/passwordSettings/"
		var response = null;
		$.ajax({
			'async': true,
			'global': false,
			'url': url,
			'dataType': "json",
			'timeout': 5000,
			'type': "POST",
			'data': {
				hashedPassword: hashedPassword,
				passwordSettings: passwordSettings
			},
			'success': function (data) {
				response = data;
			},
			'error': function () {
				response = false;
			}
		}).done(() => {

			if(response) {

				if(response.succeeded === true) {
					hashedPassword = passwordSettings.newPassword;
					if(getCookie("hashedPassword") !== "")
						setCookie("hashedPassword", hashedPassword, 7);
					//console.log("wasHere");
					this.handleEscapePasswordSettings();
				} else {
					window.alert("Fehlgeschlagen.");
				}
			} else {
				window.alert("Verbindung zum Server fehlgeschlagen.");
			}
		});


		this.handleEscapePasswordSettings();
		callback();
	};

	//Show Modal (Change Config popup)
	handleShowModal = () => {

		//define general fields
		let changeForm = $.extend(true, {}, newFormTemplate);
		let config = this.state.config[this.state.currentTabID];
		changeForm.sensorname.raw = config.name;
		changeForm.sensorname.ok = true;
		changeForm.email.raw = config.email;
		changeForm.email.ok = true;
		changeForm.messrate.processed = config.messrate / 1000
		changeForm.messrate.raw = changeForm.messrate.processed.toString();
		changeForm.jumpers = config.jumpers.slice();

		//setState general fields
		this.setState({changeForm: changeForm, showModal: true}, () => {

			//compute sensorID specific fields
			this.handleNewFormChange("sensorID", config.type, () => {

				let changeForm = this.state.changeForm;

				//fill in sensorID specific content
				this.state.catalog[config.type].channels.ids.forEach((key) => {
					let key2 = config.active.indexOf(key);
					if(key2 >= 0) {
						//process min
						if(config.min[key2]===-1) {
							changeForm.channels.minRaw[key] = "";
							changeForm.channels.min[key] = -1;
						} else {
							changeForm.channels.minRaw[key] = config.min[key2] * config.conversion[key2][0] + config.conversion[key2][1];
							changeForm.channels.min[key] = config.min[key2];
						}
						//process max
						if(config.max[key2]===-1) {
							changeForm.channels.maxRaw[key] = "";
							changeForm.channels.max[key] = -1;
						} else {
							changeForm.channels.maxRaw[key] = config.max[key2] * config.conversion[key2][0] + config.conversion[key2][1];
							changeForm.channels.max[key] = config.max[key2];
						}
						changeForm.channels.active[key] = true;
					} else {
						changeForm.channels.active[key] = false;
					}
					changeForm.channels.unit[key] = config.einheiten[key2];
				});
				this.setState({changeForm: changeForm});

			});
			// !!!!!! Schwellwerte !!!!!
		});
	};

	//Hide Modal (Abort Change Config)
	handleHideModal = () => {
		if(window.confirm("Sind Sie sicher, dass Sie abbrechen wollen? Die Änderungen werden verworfen.")) {
			this.setState({showModal: false});
		}
	};

	//delete a sensor
	handleDeleteSensor = () => {
		if(window.confirm("Sind Sie sicher, dass Sie den Sensor löschen wollen. Alle Messdaten und Sensoreinstellungen gehen dabei verloren.")) {
			//edit state
			let session_id = this.state.session_id;
			let deletedSensor = this.state.currentTabID;
			let config = $.extend(true, {}, this.state.config);
			let data   = $.extend(true, {}, this.state.data);
			let rawData= $.extend(true, {}, this.state.rawData);
			let currentTabID = this.state.currentTabID;
			config.sensors.splice(config.sensors.indexOf(deletedSensor),1);
			delete config[deletedSensor];
			currentTabID = -1;

			//send to server
			var json = null;
			$.ajax({
				'async': false,
				'global': false,
				'url': urlDev + "app/settings/",
				'dataType': "json",
				'type': "POST",
				'timeout': 2000,
				'data': {
					hashedPassword: hashedPassword,
					session_id: session_id,
					deletedSensor: deletedSensor
				},
				'success': function (data) {
					json = data;
				},
				'error': function () {
					//console.log("deletion failed");
					window.alert("Löschen des Sensors fehlgeschlagen (fehlerhafte Serverantwort).");
				}
			}).done(() => {
				if(json.succeeded) {
					window.location.hash = "add-sensor";
					delete data[deletedSensor];
					delete rawData[deletedSensor];
					this.setState({config: config, showModal: false, currentTabID: currentTabID, data: data, rawData: rawData});
				} else {
					window.alert("Löschen des Sensors fehlgeschlagen.");
				}
			});
		}
	};

	//data preparation for singleMeasurement
	returnDataSingleMeasurement = () => {
		var series = [{
			data: this.state.data[this.state.currentTabID]
		}];
		return series;
	};

	//export data to csv
	handleExportData = () => {
		//console.log("Hello World :D");

		//collect metadata
		var currentConfig = this.state.config[this.state.currentTabID];
		var sensorName = currentConfig.name;
		var currentdate = new Date(); 
		var dateTime = currentdate.getFullYear().pad(4) + "."
			+ (currentdate.getMonth()+1).pad(2)  + "." 
			+ currentdate.getDate().pad(2) + "-"  
			+ currentdate.getHours().pad(2) + "."  
			+ currentdate.getMinutes().pad(2) + "." 
			+ currentdate.getSeconds().pad(2);
		var filename = sensorName + "-" + dateTime + ".csv";

		//build file content
		var fileContent = "Messdaten von plug 'n' log"
			+ "\nHeruntergeladen am," + dateTime
			+ "\nSensorname," + sensorName
			+ "\nSensor-ID," + currentConfig.type
			+ "\nMessrate [ms]," + currentConfig.messrate
			+ "\nE-Mail," + currentConfig.email;

		fileContent += "\n\nKanalnamen,"
		currentConfig.names.forEach(function (item) {
			fileContent += "," + item;
		});
		fileContent += "\nEinheiten,";
		currentConfig.einheiten.forEach(function (item) {
			fileContent += "," + item;
		});
		fileContent += "\nZeitstempel (verstrichene ms seit 1970),formatierter Zeitstempel";
		currentConfig.einheiten.forEach(function (item) {
			fileContent += ",Messwert";
		});

		this.state.rawData[this.state.currentTabID].forEach(function (item) {
			var relativeDate = new Date(item[0]); 
			var relativeDateTime = relativeDate.getFullYear().pad(4) + "."
				+ (relativeDate.getMonth()+1).pad(2)  + "." 
				+ relativeDate.getDate().pad(2) + " - "  
				+ relativeDate.getHours().pad(2) + ":"  
				+ relativeDate.getMinutes().pad(2) + ":" 
				+ relativeDate.getSeconds().pad(2) + "." 
				+ relativeDate.getMilliseconds().pad(3);
			fileContent += "\n" + item[0] + "," + relativeDateTime;
			item[1].forEach(function (subItem, key) {
				fileContent += "," + ((subItem * currentConfig.conversion[key][0]) + (currentConfig.conversion[key][1]));
			});
		});


		//script from: https://stackoverflow.com/questions/45831191/generate-and-download-file-from-js
		var element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
		element.setAttribute('download', filename);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};








	// ------------
	// -- render --
	// ------------

	render() {

		if(this.state.stateMachine === 0)
			return (
				<div>
					<Tabs
						config={this.state.config}
						currentTabID={this.state.currentTabID}
						onChangeTab={this.handleChangeTab}
					/>
					<Warnings 
						value={this.state}
						className="warnings"
					/>
					{ this.assignTab() }
				</div>
			);
		else if (this.state.stateMachine === 1)
			return(
				<div>
					<br />
					<h1 style={{textAlign: "center"}}>{this.state.loadingText}</h1>
				</div>
			);
		else if (this.state.stateMachine === 2)
			return(
				<PasswordWall
					login={this.state.login}
					onChangePassword={this.handleChangePassword}
					onSubmitPassword={this.handleSubmitPassword}
				/>
			);
	}

	assignTab () {
		if(this.state.currentTabID === -1) {
			return (
				<NewMeasurement
					catalog={this.state.catalog}
					newForm={this.state.newForm}
					onNewFormChange={this.handleNewFormChange}
					onSubmit={this.handleSubmit}
					isNewSensor={true}
				/>
			);
		}
		if(this.state.currentTabID === -2) {
			return <Overview />;
		}
		if(this.state.currentTabID === -3) {
			return (<Settings 
				login={this.state.login}
				settings={this.state.settings}
				onOpenPasswordModal={this.handleOpenPasswordModal}
				onEscapePasswordSettings={this.handleEscapePasswordSettings}
				onChangePasswordSettings={this.handleChangePasswordSettings}
				onSubmitPasswordSettings={this.handleSubmitPasswordSettings}
			/>);
		}
		if(this.state.currentTabID >= 0) {
			return (
				<SingleMeasurement
					config={this.state.config}
					currentTabID={this.state.currentTabID}
					data={this.state.data}
					options={this.state.chart.options}
					onNewFormChange={this.handleNewFormChange}
					onSubmit={this.handleSubmit}
					onDeleteSensor={this.handleDeleteSensor}
					state={this.state}
					onShowModal={this.handleShowModal}
					onHideModal={this.handleHideModal}
					onExportData={this.handleExportData}
				/>
			);
		}
	}

}

export default Site;