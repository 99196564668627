
import React, { Component } from 'react';

class Warnings extends Component {

	state = {};

	render() {

		return(
			<div className="container-fluid warnings">
			<br />
				{


					// adds all warnings and errors (yellow and red bars below the tabs)

					this.props.value.status.map(
						warning => (
							<div className={"alert alert-"+warning.type} role="alert" key={warning.text}>
								{warning.text}
							</div>
						)
					)
				}
			</div>
		);
	}

}

export default Warnings;
