//
// form to add a new sensor
//



import React, { Component } from "react";

import { Accordion, Button, ButtonToolbar, Form, InputGroup, Row, Col, Table, Container } from "react-bootstrap";

class NewMeasurement extends Component {

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.props.onNewFormChange(name, value);
	}

	render() {
		return (
			<Container
				style={{maxWidth: 750}}
			>
				<br />
				<br />
				
				<h4>
					Grundeinstellungen{" "}
					<span className="badge badge-pill badge-primary">
						erforderlich
					</span>
				</h4>
				<br />

				<Form onSubmit={() => this.props.onSubmit()} noValidate>
					<Form.Group as={Row}>
						<Form.Label column sm={4}>
							Sensorname
						</Form.Label>
						<Col sm={8}>
							<Form.Control
								name="sensorname"
								isValid={this.props.newForm.validation && this.props.newForm.sensorname.ok}
								isInvalid={this.props.newForm.validation && !this.props.newForm.sensorname.ok}
								value={this.props.newForm.sensorname.raw}
								onChange={this.handleInputChange}
								type="text"
								id="sensorname"
							/>
							<Form.Control.Feedback type="invalid">
								Bitte geben Sie einen Namen ein.
							</Form.Control.Feedback>
							<Form.Text
								id="Sensorname_Kommentar"
								className="text-muted"
							>
								Frei wählbar, dient zur Wiedererkennung.
							</Form.Text>
						</Col>
					</Form.Group>

					{
						this.props.isNewSensor &&
						<Form.Group as={Row}>
							<Form.Label column sm={4}>
								Sensor-ID
							</Form.Label>
							<Col sm={8}>
								<Form.Control
									name="sensorID"
									isValid={this.props.newForm.validation && this.props.newForm.sensorID.ok}
									isInvalid={this.props.newForm.validation && !this.props.newForm.sensorID.ok}
									value={this.props.newForm.sensorID.raw}
									onChange={this.handleInputChange}
									type="text"
									id="sensorID"
									list="catalog"
								/>
								<datalist id="catalog">
									{
										this.props.catalog.sensors.map(
											sensor => (
												<option key={sensor}>{sensor}</option>
											)
										)
									}
								</datalist>
								<Form.Control.Feedback type="invalid">
									{this.props.newForm.sensorID.message}
								</Form.Control.Feedback>
								<Form.Text
									id="Sensor-ID_Kommentar"
									className="text-muted"
								>
									Auf dem Sensor angegeben.
								</Form.Text>
							</Col>
						</Form.Group>
					}


					<br />
					<br />
					<h4>
						Weitere Einstellungen{" "}
						<span className="badge badge-pill badge-secondary">
							optional
						</span>
					</h4>
					<br />

					<Form.Group as={Row}>
						<Form.Label column sm={4}>
							E-Mail-Adresse
						</Form.Label>
						<Col sm={8}>
							<Form.Control
								name="email"
								isValid={this.props.newForm.validation && this.props.newForm.email.ok}
								isInvalid={this.props.newForm.validation && !this.props.newForm.email.ok}
								value={this.props.newForm.email.raw}
								onChange={this.handleInputChange}
								type="text"
								id="sensorname"
							/>
							<Form.Control.Feedback type="invalid">
								Bitte geben Sie mindestens eine gültige Email-Adresse ein.
							</Form.Control.Feedback>
							<Form.Text
								id="Email_Kommentar"
								className="text-muted"
							>
								E-Mail-Adresse für Fehlermeldungen. Mehrere Adressen durch Komma trennen.
							</Form.Text>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={4}>
							Messsrate
						</Form.Label>
						<Col sm={8}>
							<InputGroup>
								<Form.Control
									name="messrate"
									isValid={this.props.newForm.validation && this.props.newForm.messrate.ok}
									isInvalid={this.props.newForm.validation && !this.props.newForm.messrate.ok}
									value={this.props.newForm.messrate.raw}
									onChange={this.handleInputChange}
									type="text"
									id="Messrate"
									aria-describedby="inputGroupAppend"
								/>
								<InputGroup.Append>
									<InputGroup.Text id="basic-addon2">
										sec
									</InputGroup.Text>
								</InputGroup.Append>
								<Form.Control.Feedback type="invalid">
									{this.props.newForm.messrate.message}
								</Form.Control.Feedback>
							</InputGroup>
							<Form.Text
								id="Messrate_Kommentar"
								className="text-muted"
							>
								Messrate des Sensors in Sekunden.
							</Form.Text>
						</Col>
					</Form.Group>
					
					{ /*
						Object.keys(this.props.newForm.einheiten).map((key) => {
							let value = this.props.newForm.einheiten[key];
							return(
								<div key={key}>
									<Form.Group as={Row}>
										<Form.Label column sm={2}>
											{"Einheit " + value.channel[1]}
										</Form.Label>
										<Col sm={6}>
											<InputGroup> 
												<InputGroup.Prepend>
													<InputGroup.Checkbox
														aria-label="Checkbox for following text input"
														name={"toggle"+key}
														checked={this.props.newForm.einheiten[key].active}
														onChange={this.handleInputChange}
														isValid={this.props.newForm.validation}
													/>
												</InputGroup.Prepend>
												{
													this.props.newForm.einheiten[key].active ?
													<Form.Control
														name={"einheit"+key}
														as="select"
														isValid={
															this.props.newForm.validation
														}
														value={
															this.props.newForm.einheiten[key].raw
														}
														onChange={
															this.handleInputChange
														}
														disabled={
															!this.props.newForm.einheiten[key].active
														}
													>
														{
															this.props.newForm.einheiten[key].einheiten.map(
																einheit => (
																	<option key={key+einheit}>{einheit}</option>
																)
															)
														}
													</Form.Control> :
													<Form.Control
														type="text"
														value="(Kanal deaktiviert)"
														disabled={true}
														valid={false}
														
													/>
												}
											</InputGroup>
										</Col>
									</Form.Group>
								</div>
							);
						})
					*/ }



					{ this.props.newForm.channels.ids.length===0 ? "" : <div>

						<Accordion>
							<Accordion.Toggle
								as={Button}
								variant="light"
								eventKey="1"
							>
								Kanaleinstellungen einblenden
							</Accordion.Toggle>

							<Accordion.Collapse eventKey="1">
								<div>

									<br />


									<Row><Col sm={12}>
										<Table style={{textAlign: "center"}} bordered >
											<thead>
												<tr>
													<th>Kanal</th>
													{
														this.props.isNewSensor &&
														<th>aktiv</th>
													}
													<th>unterer Schwellwert<br />
													<small>Feld leer: kein Schwellwert</small></th>
													<th>oberer Schwellwert<br />
													<small>Feld leer: kein Schwellwert</small></th>
													{
														this.props.isNewSensor &&
														<th>Einheit</th>
													}
												</tr>
											</thead>

											<tbody>
											{
												this.props.newForm.channels.ids.map(
													(key) => (
														(this.props.newForm.channels.active[key] || this.props.isNewSensor) &&
														<tr key={this.props.newForm.channels.names[key]}>
															<td>
																{this.props.newForm.channels.names[key]}
															</td>
															{
																this.props.isNewSensor &&
																<td>
																	<Form.Check
																		type="checkbox"
																		name={"toggle"+key}
																		checked={this.props.newForm.channels.active[key]}
																		onChange={this.handleInputChange}
																	/>
																</td>
															}
															<td>
																<InputGroup>
																	<Form.Control
																		name={"min"+key}
																		value={this.props.newForm.channels.minRaw[key]}
																		type="text"
																		aria-describedby="inputGroupAppend"
																		disabled={!this.props.newForm.channels.active[key]}
																		onChange={this.handleInputChange}
																		isValid={this.props.newForm.channels.active[key] && this.props.newForm.validation && this.props.newForm.channels.minOk[key]}
																		isInvalid={this.props.newForm.channels.active[key] && this.props.newForm.validation && !this.props.newForm.channels.minOk[key]}
																	/>
																	<InputGroup.Append>
																		<InputGroup.Text id="basic-addon2">
																			{this.props.newForm.channels.unit[key]}
																		</InputGroup.Text>
																	</InputGroup.Append>
																	<Form.Control.Feedback type="invalid">
																		{this.props.newForm.channels.minMessage[key]}
																	</Form.Control.Feedback>
																</InputGroup>
															</td>
															<td>
																<InputGroup>
																	<Form.Control
																		name={"max"+key}
																		value={this.props.newForm.channels.maxRaw[key]}
																		type="text"
																		aria-describedby="inputGroupAppend"
																		disabled={!this.props.newForm.channels.active[key]}
																		onChange={this.handleInputChange}
																		isValid={this.props.newForm.channels.active[key] && this.props.newForm.validation && this.props.newForm.channels.maxOk[key]}
																		isInvalid={this.props.newForm.channels.active[key] && this.props.newForm.validation && !this.props.newForm.channels.maxOk[key]}
																	/>
																	<InputGroup.Append>
																		<InputGroup.Text id="basic-addon2">
																			{this.props.newForm.channels.unit[key]}
																		</InputGroup.Text>
																	</InputGroup.Append>
																	<Form.Control.Feedback type="invalid">
																		{this.props.newForm.channels.maxMessage[key]}
																	</Form.Control.Feedback>
																</InputGroup>
															</td>
															{
																this.props.isNewSensor &&
																<td>
																	<Form.Control
																		as="select"
																		onChange={this.handleInputChange}
																		value={this.props.newForm.channels.unit[key]}
																		name={"einheit"+key}
																		disabled={!this.props.newForm.channels.active[key]}
																	>
																		{
																			this.props.catalog.units[this.props.newForm.channels.type[key]].map(
																				einheit => (
																					<option key={key+einheit}>{einheit}</option>
																				)
																			)
																		}
																	</Form.Control>
																</td>
															}
														</tr>
													)
												)
											}
											</tbody>
										</Table>
									</Col></Row>
								</div>
							</Accordion.Collapse>
						</Accordion>
					</div> }


					<br />

					{this.props.newForm.jumpers.length===0 ? "" : <div>
						<Accordion>
							<Accordion.Toggle
								as={Button}
								variant="light"
								eventKey="2"
							>
								Jumper-Konfiguration einblenden
							</Accordion.Toggle>

							<Accordion.Collapse eventKey="2">
								<div>
									<br />
									<Row>
										<Col sm={12}>
											<p>
												Damit der Sensor angesprochen werden kann, müssen die Einstellungen mit der Konfiguration auf dem Sensor übereinstimmen.
											</p>
										</Col>
									</Row>
									{
										Object.keys(this.props.newForm.jumpers).map((key) => {
											const value = this.props.newForm.jumpers[key];
											return(
												<Form.Group as={Row} key={key}>
													<Form.Label column sm={4}>
														{"Jumper " + key}
													</Form.Label>
													<Col sm={8}>
														<Form.Control
															name={"jumper"+key}
															as="select"
															isValid={this.props.newForm.validation}
															value={value}
															onChange={this.handleInputChange}
														>
															{
																Object.keys(this.props.newForm.jumperModes).map(
																	key2 => {
																		const mode = this.props.newForm.jumperModes[key2];
																		return(
																			<option key={mode} value={key2}>{mode}</option>
																		);
																	}
																)
															}
														</Form.Control>
													</Col>
												</Form.Group>
											);
										})
									}
								</div>
							</Accordion.Collapse>
						</Accordion>
						<br />
					</div>}

					<ButtonToolbar>
						{
							this.props.isNewSensor ? 
								(<>
									<Button type="button" variant="primary" onClick={() => this.props.onSubmit()}>
										Sensor hinzufügen
									</Button>
								</>)
							:
								(<>
									<Button
										type="button"
										variant="primary"
										onClick={() => this.props.onSubmit()}
										style={{marginRight: 10}}
									>
										Änderungen speichern
									</Button>
									<Button
										type="button"
										variant="warning"
										onClick={this.props.onHide}
										style={{marginRight: 10}}
									>
										Schliessen
									</Button>
									<Button
										type="button"
										variant="danger"
										onClick={this.props.onDeleteSensor}
									>
										Sensor löschen
									</Button>
								</>)
						}
					</ButtonToolbar>
				</Form>
				<br />
				<br />
			</Container>
		);
	}
}

export default NewMeasurement;
