
import React, { Component } from 'react';
import { Form, Container, Row, InputGroup, Button } from "react-bootstrap";

class PasswordWall extends Component {

	handleChangePassword = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.props.onChangePassword(name, value);
	};

	handleSubmitPassword = (event) => {
		event.preventDefault();
		if(this.props.login.formEnabled)
			this.props.onSubmitPassword();
	};

	render() {

		return (
			<Container style={{textAlign: "center"}}>
				<h3 style={{paddingTop: 100}}>Dieses System ist passwortgeschützt.</h3>
				<p style={{paddingTop: 5, paddingBottom: 10}}>Geben Sie bitte das Passwort ein. <span style={{color: "red"}}>{this.props.login.passwordAlert}</span></p>
				<Container
					style={{maxWidth: 300}}
				>
					<Form onSubmit={this.handleSubmitPassword} name="passwordForm" noValidate>
						<Form.Group as={Row}>
							<InputGroup>
								<Form.Control
									name="password"
									value={this.props.login.enteredPassword}
									onChange={this.handleChangePassword}
									type="password"
									id="password"
								/>
								<InputGroup.Append>
									<Button type="button" variant={this.props.login.formEnabled ? "primary" : "secondary"} onClick={this.handleSubmitPassword}>
										&rarr;
									</Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
							<Form.Check
								type="checkbox"
								id="rememberLoginButton"
								name="rememberLogin"
								checked={this.props.login.rememberLogin}
								onChange={this.handleChangePassword}
								label="Login merken"
							/>
					</Form>
				</Container>
			</Container>
		);
	}

}

export default PasswordWall;