
import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import NewMeasurement from './newMeasurement';

class SingleMeasurement extends Component {

	render() {

		return (
			<div className="container-fluid">
			<br />
				<h4 title={"ID: "+this.props.currentTabID}>{this.props.config[this.props.currentTabID].name}</h4>
				<Chart
					options={this.props.options}
					series={this.props.data[this.props.currentTabID]}
					type="line"
					height="400"
				/>

				<Button
					type="button"
					onClick={this.props.onShowModal}
					style={{marginRight: 10}}
				>
					Sensoreinstellungen
				</Button> 

				<Button
					type="button"
					onClick={this.props.onExportData}
				>
					Sensordaten herunterladen
				</Button>

				<Modal
					show={this.props.state.showModal}
					as={Container}
					onHide={this.props.onHideModal}
					centered={true}
					width={1000}
				>
					<Row><Col>
						<NewMeasurement
							catalog={this.props.state.catalog}
							newForm={this.props.state.changeForm}
							onNewFormChange={this.props.onNewFormChange}
							onSubmit={this.props.onSubmit}
							onDeleteSensor={this.props.onDeleteSensor}
							onHide={this.props.onHideModal}
							isNewSensor={false}
						/>
					</Col></Row>
				</Modal>

				<br /><br />
			</div>
		);
	}

}

export default SingleMeasurement;