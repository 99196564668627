
import React, { Component } from 'react';
import { Button, Form, Modal, Row, Col, Container } from "react-bootstrap";


class Settings extends Component {

	handleChangePasswordSettings = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.props.onChangePasswordSettings(name, value);
	};

	handleOpenPasswordModal = (event) => {
		this.props.onOpenPasswordModal(event.target.name);
	};

	returnColor = () => {
		switch(this.props.settings.passwordSettings.type) {
			case "activatePassword":
				return("success");
			case "deactivatePassword":
				return("danger");
			case "changePassword":
			default:
				return("primary");
		}
	};

	handleSubmitPasswordSettings = (event) => {
		event.preventDefault();
		this.props.onSubmitPasswordSettings();
	};


	render() {

		return (
			<Container
				style={{maxWidth: 750}}
			>
				<br />
				<br />
				
				<h4>Passwortschutz</h4>
				<br />
				{ this.props.login.loginActivated ?
					<>
						<Button
							type="button"
							name="changePassword"
							onClick={this.handleOpenPasswordModal}
							variant="primary"
							style={{marginRight: 10}}
						>
							Passwort ändern
						</Button>

						<Button
							type="button"
							name="deactivatePassword"
							onClick={this.handleOpenPasswordModal}
							variant="danger"
						>
							Passwortschutz deaktivieren
						</Button>
					</>
				:
					<Button
						type="button"
						name="activatePassword"
						onClick={this.handleOpenPasswordModal}
						variant="success"
					>
						Passwortschutz aktivieren
					</Button>
				}

				<Modal
					show={this.props.settings.passwordSettings.showModal}
					as={Container}
					onHide={this.props.onEscapePasswordSettings}
					centered={true}
					width={1000}
				>
					<Row><Col>
						<Container
							style={{maxWidth: 750}}
						>

							<Form onSubmit={() => this.handleSubmitPasswordSettings()} noValidate>
								
								<br />

								<h4>
									{ this.props.settings.passwordSettings.type === "activatePassword" ? "Passwortschutz aktivieren" : "" }
									{ this.props.settings.passwordSettings.type === "changePassword" ? "Passwort ändern" : "" }
									{ this.props.settings.passwordSettings.type === "deactivatePassword" ? "Passwortschutz deaktivieren" : "" }	
								</h4><br />

								{this.props.settings.passwordSettings.type === "changePassword" || this.props.settings.passwordSettings.type === "deactivatePassword" ?
									<Form.Group as={Row}>
										<Form.Label column sm={6}>
											aktuelles Passwort
										</Form.Label>
										<Col sm={6}>
											<Form.Control
												name="currentPassword"
												isValid={this.props.settings.passwordSettings.validation && this.props.settings.passwordSettings.currentPassword.isValid}
												isInvalid={this.props.settings.passwordSettings.validation && !this.props.settings.passwordSettings.currentPassword.isValid}
												value={this.props.settings.passwordSettings.currentPassword.value}
												onChange={this.handleChangePasswordSettings}
												type="password"
												id="currentPassword"
											/>
											<Form.Control.Feedback type="invalid">
												{this.props.settings.passwordSettings.currentPassword.feedback}
											</Form.Control.Feedback>
											<Form.Text
												id="currentPassword-comment"
												className="text-muted"
											>
											</Form.Text>
										</Col>
									</Form.Group>
								: "" }

								{this.props.settings.passwordSettings.type === "activatePassword" || this.props.settings.passwordSettings.type === "changePassword" ?
									<Form.Group as={Row}>
										<Form.Label column sm={6}>
											neues Passwort
										</Form.Label>
										<Col sm={6}>
											<Form.Control
												name="newPassword1"
												isValid={this.props.settings.passwordSettings.validation && this.props.settings.passwordSettings.newPassword1.isValid}
												isInvalid={this.props.settings.passwordSettings.validation && !this.props.settings.passwordSettings.newPassword1.isValid}
												value={this.props.settings.passwordSettings.newPassword1.value}
												onChange={this.handleChangePasswordSettings}
												type="password"
												id="newPassword1"
											/>
											<Form.Control.Feedback type="invalid">
												{this.props.settings.passwordSettings.newPassword1.feedback}
											</Form.Control.Feedback>
											<Form.Text
												id="newPassword1-comment"
												className="text-muted"
											>
											</Form.Text>
										</Col>
									</Form.Group>
								: "" }

								{this.props.settings.passwordSettings.type === "activatePassword" || this.props.settings.passwordSettings.type === "changePassword" ?
									<Form.Group as={Row}>
										<Form.Label column sm={6}>
											neues Passwort wiederholen
										</Form.Label>
										<Col sm={6}>
											<Form.Control
												name="newPassword2"
												isValid={this.props.settings.passwordSettings.validation && this.props.settings.passwordSettings.newPassword2.isValid}
												isInvalid={this.props.settings.passwordSettings.validation && !this.props.settings.passwordSettings.newPassword2.isValid}
												value={this.props.settings.passwordSettings.newPassword2.value}
												onChange={this.handleChangePasswordSettings}
												type="password"
												id="newPassword2"
											/>
											<Form.Control.Feedback type="invalid">
												{this.props.settings.passwordSettings.newPassword2.feedback}
											</Form.Control.Feedback>
											<Form.Text
												id="newPassword2-comment"
												className="text-muted"
											>
												Passworttipps: Mindestens 8 Zeichen, inkl. Gross-, Kleinschreibung, Zahlen und Sonderzeichen.
											</Form.Text>
										</Col>
									</Form.Group>
								: "" }

								<Button
									type="submit"
									name="deactivatePassword"
									onClick={this.handleSubmitPasswordSettings}
									style={{marginTop: 15, marginRight: 15, marginBottom: 20}}
									variant={this.returnColor()}	
								>
									{ this.props.settings.passwordSettings.type === "activatePassword" ? "Passwortschutz aktivieren" : "" }
									{ this.props.settings.passwordSettings.type === "changePassword" ? "Passwort ändern" : "" }
									{ this.props.settings.passwordSettings.type === "deactivatePassword" ? "Passwortschutz deaktivieren" : "" }	
								</Button>

								<Button
									type="button"
									name="deactivatePassword"
									onClick={this.props.onEscapePasswordSettings}
									style={{marginTop: 15, marginBottom: 20}}
									variant="secondary"	
								>
									Abbrechen
								</Button>

							</Form>

						</Container>
					</Col></Row>
				</Modal>

				<p style={{color: "grey", position: "fixed", right: 10, bottom: 10, margin: 0, padding: 0, fontSize: 11}} id="build-info">V1.2.1, build 02.02.2021 #2</p>

			</Container>

		);
	}

}

export default Settings;